
/* eslint-disable no-param-reassign */

import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys'; import type DocumentFiltersService from '@/modules/document-filters/document-filters.service';

import ScheduledReportsPopup from '@/modules/reports';
import { DATA_TYPE, SCHEDULER_CONFIG, DAY_CONFIG } from '@/modules/reports/constants';
import {
    IProperties, IFilterItem, ISchedulerConfig, IRecipient, IForm,
} from '@/modules/reports/interfaces';
import CurrencySwitcher from '@/modules/common/components/currency-switcher.vue';
import DEFAULT_LOS from '@/modules/document-filters/constants/default-los.constant';
import { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import loop24 from '@/modules/common/filters/loop-24.filter';
import { ComplexPair, Value } from '@/modules/reports/interfaces/filter-item.interface';
import MealTypeModel from '@/modules/meal-types/models/meal-type.model';
import MealTypesService, { MealTypesServiceS } from '@/modules/meal-types/meal-types.service';
import RoomTypesService, { RoomTypesServiceS } from '@/modules/room-types/room-types.service';
import RoomTypeModel from '@/modules/room-types/models/room-type.model';
import PRICE_TYPE from '@/modules/document-filters/constants/price-type.constant';
import PRICE_SHOWN from '@/modules/rates/constants/price-shown.constant';
import ANY_MEAL_TYPE from '@/modules/meal-types/constants/any-meal-type.constant';
import ANY_ROOM_TYPE from '@/modules/room-types/constants/any-room-type.constant';
import { FE_ONLY_PROVIDERS } from '@/modules/providers/constants';
import { SettingsGeneralService } from '@/modules/settings/settings-general.service';
import type ProvidersService from '@/modules/providers/providers.service';
import type CompsetsService from '@/modules/compsets/compsets.service';

import RatesAnalysisFiltersService, { RatesAnalysisFiltersServiceS } from '../../rates-analysis-filters.service';
import RatesAnalysisService, { RatesAnalysisServiceS } from '../../rates-analysis.service';
import type RatesFiltersService from '../../rates-filters.service';

const keyMap = {
    'past period': 'diffDays',
    source: 'providers',
    mealType: 'meal_type',
    roomType: 'room_type',
    los: 'los',
    pos: 'pos',
    'number of guest': 'occupancy',
    price: 'price',
} as Record<string, string>;

const analysisKeyMap = {
    diffDays: 'past period',
    provider: 'source',
    mealTypeId: 'mealType',
    roomTypeId: 'roomType',
    los: 'los',
    pos: 'pos',
    numberOfGuests: 'number of guest',
    priceType: 'price',
} as Record<string, string>;

const compareList = [
    { value: 'past period', name: 'titles.pastPeriod' },
    { value: 'source', name: 'titles.provider' },
    { value: 'mealType', name: 'titles.mealType' },
    { value: 'roomType', name: 'titles.roomType' },
    { value: 'los', name: 'titles.los' },
    { value: 'pos', name: 'titles.pos' },
    { value: 'number of guest', name: 'titles.numberOfGuests' },
    { value: 'price', name: 'titles.price' },
];

@Component({
    components: {
        ScheduledReportsPopup,
    },
})
export default class ScheduledReportsModalPage extends Vue {
    @inject(KEY.RatesFiltersService) private ratesFiltersService!: RatesFiltersService;
    @inject(KEY.DocumentFiltersService) private documentFiltersService!: DocumentFiltersService;
    @inject(KEY.SettingsGeneralService) private settingsGeneralService!: SettingsGeneralService;
    @inject(RatesAnalysisFiltersServiceS) private ratesAnalysisFiltersService!: RatesAnalysisFiltersService;
    @inject(KEY.CompsetsService) private compsetsService!: CompsetsService;
    @inject(KEY.ProvidersService) private providersService!: ProvidersService;
    @inject(MealTypesServiceS) private mealTypesService!: MealTypesService;
    @inject(RoomTypesServiceS) private roomTypesService!: RoomTypesService;
    @inject(RatesAnalysisServiceS) ratesAnalysisService!: RatesAnalysisService;

    form: IForm = {
        filters: {},
    } as IForm;

    get properties(): IProperties {
        return {
            dataType: DATA_TYPE.RATES_COMPARE,
            dateRange: {
                options: [30, 60, 90],
                value: 30,
            },
            fileType: {
                options: ['EXCEL'],
                value: 'EXCEL',
            },
        };
    }

    get customColumns() {
        return [];
    }

    get filters(): IFilterItem[] {
        return [
            this.compsetFilter,
            this.posFilter,
            this.providerFilter,
            this.losFilter,
            this.mealTypeFilter,
            this.roomTypeFilter,
            this.occupancyFilter,
            this.priceTypeFilter,
            this.priceShownFilter,
            this.currencyFilter,
            this.compareFilter,
        ] as IFilterItem[];
    }

    get defaultFrequency() {
        return {
            type: SCHEDULER_CONFIG.DAILY,
            hour: loop24(new Date().getTimezoneOffset() / 60),
            minute: 0,
            month: 1,
            dayOfWeek: '6',
            dayOfMonth: 1,
            monthPeriod: DAY_CONFIG.FIRST,
            repeatEvery: 1,
        };
    }

    get frequency(): ISchedulerConfig {
        return this.defaultFrequency;
    }

    get recipients(): IRecipient[] {
        return [];
    }

    private get compset() {
        const { filters: values = {} } = this.form;

        return this.compsetsService.getCompset(values.compset as string)
            || this.compsetsService.currentCompset;
    }

    private get compsetFilter() {
        const { filters } = this.form;
        const { compsets, currentCompset } = this.compsetsService;

        if (!compsets) {
            return {};
        }

        const options = compsets.map(compset => ({
            name: compset.name,
            value: compset.id,
        }));

        const value = filters?.compset || currentCompset?.id || options[0].value;

        return {
            name: 'compset',
            label: this.$tc('titles.compset'),
            value,
            options,
            disableOnEdit: true,
        } as IFilterItem;
    }

    private get providerFilter() {
        const { filters } = this.form;
        const { compset } = this;

        const providerList = (compset?.rateProviders || [])
            .filter(p => !FE_ONLY_PROVIDERS.includes(p));

        let choosenProvider = filters?.providers
            || this.ratesFiltersService.settings.provider;

        if (!providerList.includes(choosenProvider as string)) {
            [choosenProvider] = providerList;
            this.form.filters = {
                ...(this.form.filters || {}),
                providers: choosenProvider,
            };
        }

        return {
            name: 'providers',
            label: this.$tc('titles.provider'),
            value: choosenProvider,
            options: providerList
                .map(p => ({
                    name: this.providersService.allProviders[p].label,
                    value: p,
                })),
            disableOnEdit: false,
        };
    }

    private get posFilter() {
        const { filters } = this.form;
        const { compset } = this;

        const posItems = compset?.pos
            || [];

        let choosenPOS = filters?.pos || this.documentFiltersService.settings.pos;

        if (!posItems.includes(choosenPOS as string)) {
            [choosenPOS] = posItems;
            this.form.filters = {
                ...(this.form.filters || {}),
                pos: choosenPOS,
            };
        }

        return {
            name: 'pos',
            label: this.$tc('titles.pos'),
            value: choosenPOS,
            options: posItems.map(p => ({ name: p, value: p })),
            disableOnEdit: false,
        };
    }

    private get losFilter() {
        const { filters } = this.form;

        const choosenLOS = filters?.los
            || this.documentFiltersService.settings.los
            || DEFAULT_LOS[0];

        return {
            name: 'los',
            label: this.$tc('titles.los'),
            value: choosenLOS,
            options: DEFAULT_LOS.map(l => ({
                name: String(l),
                value: l,
            })),
            disableOnEdit: false,
        };
    }

    private get mealTypeFilter() {
        const { filters } = this.form;
        const { mealTypes } = this.mealTypesService;
        const { mealTypeId: mainMealTypeId } = this.ratesFiltersService.settings;

        const mealTypeId = mainMealTypeId === ANY_MEAL_TYPE.id
            ? ANY_MEAL_TYPE.name
            : this.mealTypesService.getMealType(mainMealTypeId)!.name;

        const choosenMealType = filters?.meal_type || mealTypeId;

        return {
            name: 'meal_type',
            label: this.$tc('titles.mealType'),
            value: choosenMealType,
            options: mealTypes
                .map((mealType: MealTypeModel) => ({
                    value: mealType.name,
                    name: this.$tc(mealType.displayName),
                })),
            disableOnEdit: false,
        };
    }

    private get roomTypeFilter() {
        const { filters } = this.form;
        const { roomTypeId } = this.ratesFiltersService.settings;

        const selectedRoomTypes = roomTypeId === -1
            ? this.roomTypesService.realRooms?.map(r => r.name).filter(r => r !== ANY_ROOM_TYPE.name)
            : [this.roomTypesService.getRoomType(roomTypeId)!.name];

        return {
            name: 'room_type',
            label: this.$tc('titles.roomType'),
            value: filters?.room_type || selectedRoomTypes,
            options: (this.roomTypesService.realRooms || [])
                .map((room: RoomTypeModel) => ({
                    value: room.name,
                    name: this.$tc(room.name),
                })),
            disableOnEdit: false,
        };
    }

    private get occupancyFilter() {
        const { filters } = this.form;

        const choosenOccupancy = filters?.occupancy
            || this.ratesFiltersService.settings.numberOfGuests
            || this.settingsGeneralService.defaultFilters.numberOfGuests;

        return {
            name: 'occupancy',
            label: this.$tc('titles.numberOfGuests'),
            value: choosenOccupancy as number,
            options: Array
                .from({ length: 10 })
                .map((_, i) => i + 1)
                .map(e => ({
                    value: e,
                    name: this.$tc('filters.guests', e, [e]).toString(),
                })),
            disableOnEdit: false,
        };
    }

    private get priceShownFilter() {
        const { filters } = this.form;

        let priceShownValue = filters?.price_type;

        if (!priceShownValue) {
            switch (this.documentFiltersService.priceShown) {
                case PRICE_SHOWN.TOTAL:
                    priceShownValue = 'total_price';
                    break;
                case PRICE_SHOWN.NET:
                    priceShownValue = 'net_price';
                    break;
                default:
                    priceShownValue = 'shown_price';
                    break;
            }
        }

        return {
            name: 'price_type',
            label: this.$tc('titles.priceShown'),
            value: priceShownValue,
            options: [
                { value: 'shown_price', name: this.$tc('filters.price.shown') },
                { value: 'net_price', name: this.$tc('filters.price.net') },
                { value: 'total_price', name: this.$tc('filters.price.total') },
            ],
            disableOnEdit: false,
        };
    }

    private get priceTypeFilter() {
        const { filters } = this.form;

        const choosenPriceType = filters?.price
            || this.ratesFiltersService.settings.priceType
            || this.settingsGeneralService.defaultFilters.price;

        return {
            name: 'price',
            label: this.$tc('titles.price'),
            value: choosenPriceType as PRICE_TYPE,
            options: [
                {
                    name: this.$tc(`price.${PRICE_TYPE.LOWEST}`),
                    value: PRICE_TYPE.LOWEST,
                },
                {
                    name: this.$tc(`price.${PRICE_TYPE.LOWEST_FLEX}`),
                    value: PRICE_TYPE.LOWEST_FLEX,
                },
                {
                    name: this.$tc(`price.${PRICE_TYPE.BEST_FLEX}`),
                    value: PRICE_TYPE.BEST_FLEX,
                },
                {
                    name: this.$tc(`price.${PRICE_TYPE.NON_REFUNDABLE}`),
                    value: PRICE_TYPE.NON_REFUNDABLE,
                },
            ],
            disableOnEdit: false,
        };
    }

    private getMainValue(compareKey: string): (string | number) | (string | number)[] {
        const { filters } = this.form;
        const mainValue = (filters || {})[keyMap[compareKey]];

        switch (compareKey) {
            case 'mealType':
                return mainValue !== ANY_MEAL_TYPE.name
                    ? this.mealTypesService.getMealType(mainValue as string)?.id || -1
                    : '';

            case 'roomType':
                return mainValue !== ANY_ROOM_TYPE.name
                    ? this.roomTypesService.getRoomType(mainValue as string)?.id || -1
                    : '';

            default:
                return mainValue as string | number;
        }
    }

    private getCompareValue(compareKey: string) {
        const { filters } = this.form;

        let compareValue = !filters?.comparison_to
            ? this.ratesAnalysisFiltersService.comparisonValues.map(v => v.value)
            : (filters.comparison_to as ComplexPair<(string | number)[]>).value as (string | number)[];

        if (!filters?.comparison_to) {
            switch (compareKey) {
                case 'mealType':
                    compareValue = compareValue
                        .map(v => this.mealTypesService.getMealType(v)!.name);
                    break;

                case 'roomType':
                    compareValue = compareValue
                        .map(v => this.roomTypesService.getRoomType(v)!.name);
                    break;

                default: break;
            }
        }

        return compareValue;
    }

    private getActualProviderList() {
        const { filters } = this.form;

        const compset = this.compsetsService.getCompset(filters?.compset as string)
            || this.compsetsService.currentCompset;
        return (compset?.rateProviders || [])
            .filter(p => !FE_ONLY_PROVIDERS.includes(p));
    }

    private get compareFilter() {
        const { filters } = this.form;

        const compareKey = !filters?.comparison_to
            ? analysisKeyMap[this.ratesAnalysisFiltersService.comparisonKey]
            : (filters.comparison_to as ComplexPair<(string | number)[]>).key as string;

        const providerList = this.getActualProviderList();
        const compareValue = this.getCompareValue(compareKey);

        const compareValues = Object
            .entries(analysisKeyMap)
            .reduce((acc, pair) => {
                const [compareKey, filterKey] = pair;
                const mainValue = this.getMainValue(filterKey)!;

                switch (filterKey) {
                    case 'mealType':
                        acc[filterKey] = () => this.ratesAnalysisFiltersService
                            .getFilterItemsExceptValue(compareKey, mainValue)
                            .map(item => ({
                                name: item.name,
                                value: this.mealTypesService.getMealType(item.value)!.name,
                            }));
                        break;

                    case 'roomType':
                        acc[filterKey] = () => this.ratesAnalysisFiltersService
                            .getFilterItemsExceptValue(compareKey, mainValue)
                            .map(item => ({
                                name: item.name,
                                value: item.name,
                            }));
                        break;
                    case 'source':
                        acc[filterKey] = () => providerList
                            .filter(p => p !== mainValue)
                            .map(value => ({
                                name: this.providersService.allProviders[value].label,
                                value,
                            }));
                        break;

                    default:
                        acc[filterKey] = () => this.ratesAnalysisFiltersService
                            .getFilterItemsExceptValue(compareKey, mainValue);
                        break;
                }

                return acc;
            }, {} as Record<string, () => Item[]>);

        return {
            name: 'comparison_to',
            label: this.$tc('titles.compareto'),
            value: [compareKey, compareValue],
            options: [
                compareList.map(({ name, value }) => ({ value, name: this.$tc(name) })),
                Object
                    .values(compareValues)
                    .map(getItems => getItems()),
            ],
            maxSelected: 2,
            disableOnEdit: [false, false],
        };
    }

    private get currencyFilter() {
        const { filters: values = {} } = this.form;

        const choosenCurrency = (values.requested_currency || this.settingsGeneralService.displayCurrency || '-') as string | null;

        return {
            name: 'requested_currency',
            label: this.$tc('titles.currency'),
            value: choosenCurrency as string,
            options: [],
            disableOnEdit: false,
            component: CurrencySwitcher,
            componentProps: {
                posValue: '-',
            },
        };
    }
}
